import { getProfile } from "./profile";
import axios from "../axios";

const fetchCompletedActivities = () => {
  try {
    return axios({
      url: "/v1/auth/profile/activites/completed",
    });
  } catch (error) {
    return error;
  }
};

const fetchActivity = (topicId) => {
  try {
    return axios({
      url: `/v1/auth/subjects/topic/${topicId}/activities`,
    });
  } catch (error) {
    return error;
  }
};

const updateActivityCompleted = async ({ activityId }) => {
  try {
    const res = await axios({
      url: `/v1/auth/wallet/activity`,
      method: "POST",
      data: { activityId },
    });
    if (res.status === 201) {
      const response = await getProfile();
      return response.data;
    }
    return res;
  } catch (error) {
    return error;
  }
};

export default fetchCompletedActivities;

export { fetchActivity, updateActivityCompleted };
