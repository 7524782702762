import i18n from "i18next";
import { useState } from "react";
import { MinusCircle, PlusCircle } from "react-feather";
import { uid } from "react-uid";
import mobile from "../../../assets/images/landingpage/qnaGirl.png";
import "./QuesAns.css";

function QuesAns() {
  const array = [
    {
      ques: i18n.t("home:qASection.array.0.ques"),
      ans: i18n.t("home:qASection.array.0.ans"),
    },
    {
      ques: i18n.t("home:qASection.array.1.ques"),
      ans: i18n.t("home:qASection.array.1.ans"),
    },
    {
      ques: i18n.t("home:qASection.array.2.ques"),
      ans: i18n.t("home:qASection.array.2.ans"),
    },
  ];
  const title = {
    fst: i18n.t("home:qASection.mainTitle.fst"),
    snd: i18n.t("home:qASection.mainTitle.snd"),
  };
  const [divKey, setDivKey] = useState(0);
  const activeDiv = (key) => {
    setDivKey(key);
  };
  const deActiveDiv = () => {
    setDivKey(-1);
  };

  return (
    <div className="ques-ans-section">
      <div className="q-a-section">
        <div className="left-section">
          <div className="image-section">
            <img src={mobile} alt="" />
          </div>
        </div>
        <div className="right-section">
          <div className="title">
            <span>{title.fst}</span>
            <span className="color-FFEB3B">{title.snd}</span>
          </div>
          <div className="ques-ans-content">
            {array.map((obj, key) => (
              <div key={uid(obj)}>
                <div
                  className={`${
                    divKey === key ? "active color-FFFFFF" : "set-border "
                  } ques-div`}
                >
                  <div className="ques">{obj.ques} </div>
                  <div className="cursor">
                    {divKey === key ? (
                      <MinusCircle onClick={() => deActiveDiv(key)} />
                    ) : (
                      <PlusCircle onClick={() => activeDiv(key)} />
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    divKey === key ? "displayAnswer" : "noneAnswer"
                  }`}
                >
                  {obj.ans}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default QuesAns;
