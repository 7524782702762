import { withTranslation } from "react-i18next";
import "./App.css";
import Statistics from "./Statistics";
import "./add.css";
import Navbar from "./components/Navbar";
import "./main.css";
import BottomSlider from "./pages/LandingPage/BottomSlider";
import EducationSection from "./pages/LandingPage/EducationSection";
import Footer from "./pages/LandingPage/Footer";
import FooterLink from "./pages/LandingPage/FooterLink";
import InTheNews from "./pages/LandingPage/InTheNews/InTheNews";
import LearningPotential from "./pages/LandingPage/LearningPotential";
import MainSection from "./pages/LandingPage/MainSection";
import CardSection from "./pages/LandingPage/MainSection/CardSection";
import QuesAns from "./pages/LandingPage/QuesAns";
import Scroller from "./pages/LandingPage/Scroller";
import TalkSection from "./pages/LandingPage/TalkSection";
import SetsApart from "./pages/SetsApart";

function MainFile() {
  return (
    <div className="App">
      <div
        className={`full-div display-main-section
        `}
      >
        <div className="header-component">
          <Navbar color="#760A98" />
          <MainSection />
          <Statistics />
        </div>
        <CardSection />
        <LearningPotential />
        <Scroller />
        <div className="main-bottom-section">
          <SetsApart />
          <EducationSection />
          <TalkSection />
          <QuesAns />
          <InTheNews />
          <BottomSlider />
          <Footer />
          <FooterLink />
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(MainFile);
