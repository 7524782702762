import React from "react";

function Logout({ hide, handleLogout }) {
  const handleLogoutClick = async (event) => {
    await handleLogout(event);
    hide();
  };
  return (
    <>
      <h3 style={{ marginTop: "2rem" }}>Come Back Soon!!! kiddoo</h3>
      <span style={{ marginTop: "1rem" }}>
        Are you sure you want to logout?
      </span>
      <div style={{ display: "flex", width: "100%", marginTop: "2rem" }}>
        <button
          type="button"
          className="modal-button"
          onClick={() => handleLogoutClick(false)}
        >
          cancel
        </button>
        <button
          type="button"
          className="modal-button"
          onClick={() => handleLogoutClick(true)}
        >
          Yes, logout
        </button>
      </div>
    </>
  );
}

export default Logout;
