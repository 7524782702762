import BounceLoader from "react-spinners/BounceLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  color: "red",
};

const Loader = ({ loading = true }) => (
  <div
    className="sweet-loading"
    style={{ height: "100vh", display: "grid", placeItems: "center" }}
  >
    <BounceLoader color="#000" loading={loading} css={override} size={150} />
  </div>
);

export default Loader;
