import axios from "../axios";

const getAppConfig = async () => {
  try {
    return await axios({
      method: "get",
      url: "/v2/config",
    });
  } catch (error) {
    return error;
  }
};

export default getAppConfig;
