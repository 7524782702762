/* eslint-disable no-underscore-dangle */
import axios from "axios";
import { getAccessToken } from "../utils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    config.headers.authorization = `Bearer ${getAccessToken()}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
