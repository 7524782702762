import axios from "../axios";

const fetchSchools = async () => {
  try {
    return await axios({
      method: "get",
      url: "/v1/student/schools",
    });
  } catch (error) {
    return error;
  }
};

export default fetchSchools;
