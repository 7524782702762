import React from "react";
import { useNavigate } from "react-router-dom";
import "./SelectClass.css";

// assets
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { toast } from "react-toastify";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  cherriHoldingMobile
} from "../../../assets/images/auth";
import { Footer } from "../../../components";
import MuiButtonSolid from "../../../components/Buttons/MuiButtons";
import Navbar from "../../../components/Navbar";
import { useAuthContext } from "../contexts/AuthContext";

const SelectClass = () => {
  const navigate = useNavigate();

  const { grade, setGrade, board, setBoard } = useAuthContext();
  const handleContinue = () => {
    if (grade && board) navigate("/location");
    else toast.error("Fill in all the fields");
  };

  return (
    <>
      <Navbar />
      <div className="selectClass">
        <div className="selectClassCotainer">
          <div className="selectClassContainerLeft">
            <img
              className="cherriImage"
              src={cherriHoldingMobile}
              alt="cherri Holding Mobile"
            />
          </div>
          <div className="selectClassContainerRight">
            <h1>Choose class</h1>
            <div className="selectClassContainer__choose">
              {["1", "2", "3", "4", "5"].map((val) => (
                <button
                  type="button"
                  onClick={() => setGrade(val)}
                  className={`${
                    grade === val ? "selectClassContainer__chooseClass" : ""
                  }`}
                >
                  {val}
                </button>
              ))}
            </div>
            <div className="selectClassContainerRightRow">
              <button
                id="cbseEnglish"
                className="selectClassContainerRightBox"
                type="button"
                style={{
                  backgroundColor:
                    board === "CBSE English"
                      ? "rgba(255, 142, 158, 0.12)"
                      : "#ffffff",
                }}
                onClick={() => setBoard("CBSE English")}
              >
                {board === "CBSE English" && (
                  <CheckCircleIcon
                    sx={{
                      color: "#31D95E",
                    }}
                  />
                )}
                <p>CBSE English</p>
              </button>

              <button
                id="Karnataka English"
                className="selectClassContainerRightBox"
                type="button"
                style={{
                  backgroundColor:
                    board === "Karnataka English"
                      ? "rgba(255, 142, 158, 0.12)"
                      : "#ffffff",
                }}
                onClick={() => setBoard("Karnataka English")}
              >
                {board === "Karnataka English" && (
                  <CheckCircleIcon
                    sx={{
                      color: "#31D95E",
                    }}
                  />
                )}
                <p>Karnataka English</p>
              </button>
              <button
                id="Karnataka Kannada"
                className="selectClassContainerRightBox"
                type="button"
                style={{
                  backgroundColor:
                    board === "Karnataka Kannada"
                      ? "rgba(255, 142, 158, 0.12)"
                      : "#ffffff",
                }}
                onClick={() => setBoard("Karnataka Kannada")}
              >
                {board === "Karnataka Kannada" && (
                  <CheckCircleIcon
                    sx={{
                      color: "#31D95E",
                    }}
                  />
                )}

                <p>Karnataka Kannada</p>
              </button>
            </div>
            <MuiButtonSolid
              EndIcon={ArrowRightAltIcon}
              title="Continue"
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleContinue}
              size="medium"
              fontSize="1rem"
              disabled={false}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SelectClass;
