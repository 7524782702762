/* eslint-disable react/self-closing-comp */
import { uid } from "react-uid";

import AliceCarousel from "react-alice-carousel";
import "./Statistics.css";
import BookImg from "./assets/images/landingpage/book1.png";
import HomeImg from "./assets/images/landingpage/buildings.png";
import RateImg from "./assets/images/landingpage/rate1.png";
import StudentImg from "./assets/images/landingpage/arrowNeon.png";
import i18n from "./i18";

const Statistics = () => {
  const array = [
    {
      svg: StudentImg,
      numbers: i18n.t("home:mainSection.array.0.numbers"),
      des: i18n.t("home:mainSection.array.0.des"),
    },
    {
      svg: BookImg,
      numbers: i18n.t("home:mainSection.array.1.numbers"),
      des: i18n.t("home:mainSection.array.1.des"),
    },
    {
      svg: HomeImg,
      numbers: i18n.t("home:mainSection.array.2.numbers"),
      des: i18n.t("home:mainSection.array.2.des"),
    },
    {
      svg: RateImg,
      numbers: i18n.t("home:mainSection.array.3.numbers"),
      des: i18n.t("home:mainSection.array.3.des"),
    },
  ];

  return (
    <div className="statistics">
      <div className="bottom-image-section display-card">
        {array.map((obj, i) => (
          <>
            <div className="img-div-section" key={uid(obj)}>
              <div className="image-section">
                <div className="img-section">
                  <img src={obj.svg} alt="cherri-learn" />
                </div>
              </div>
              <div className="des-section">
                <div className="title">
                  <span className="color-FFFFFF">{obj.numbers}</span>
                  <span className="color-FFEB3B">+</span>
                </div>
                <div className="sub-title color-FFFFFF">{obj.des}</div>
              </div>
            </div>

            {i !== array.length - 1 && (
              <div
                style={{ borderLeft: "1px solid #E8EAF6", height: 70 }}
                className="vl"
              ></div>
            )}
          </>
        ))}
      </div>
      <div className="display-slider">
        <AliceCarousel
          mouseTracking
          responsive={{
            0: { items: 1 },
            360: { items: 2 },
          }}
          width="90%"
          autoPlay
          infinite
          activeIndex={1}
          disableButtonsControls
          animationDuration={1000}
        >
          {array.map((obj) => (
            <div key={uid(obj)}>
              <div className="image-section">
                <div className="img-section">
                  <img src={obj.svg} alt="cherri-learn" />
                </div>
              </div>
              <div className="des-section">
                <div className="title">
                  <span className="color-FFFFFF">{obj.numbers}</span>
                  <span className="color-FFEB3B">+</span>
                </div>
                <div className="sub-title color-FFFFFF">{obj.des}</div>
              </div>
            </div>
          ))}
        </AliceCarousel>
      </div>
    </div>
  );
};
export default Statistics;
