import axios from "axios";

const fetchSubjects = ({ grade, medium, board }) => {
  try {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/v1/web/subjects?grade=${grade}&medium=${medium}&board=${board}`,
    });
  } catch (error) {
    return error.response;
  }
};

export default fetchSubjects;
