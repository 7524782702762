import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import {  getMessaging, getToken, onMessage  } from "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBAtxD9--xoMv7tEmRpmrIECdaKA97ZD6Y",
  authDomain: "pteacher-app.firebaseapp.com",
  projectId: "pteacher-app",
  storageBucket: "pteacher-app.appspot.com",
  messagingSenderId: "561372189600",
  appId: "1:561372189600:web:8c2b530cc09f84b1b80ac5",
  measurementId: "G-W29XVB0D99",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const messaging = getMessaging(app);

// function requestPermission() {
//   console.log('Requesting permission...');
//   Notification.requestPermission().then((permission) => {
//     if (permission === 'granted') {
//       console.log('Notification permission granted.');
//     }
//   })
// }

const requestForToken = () => 
   getToken(messaging, { vapidKey: 'BId89ifjIeJFl2kP4M7-jV9dBh9SP-CcQTF_urTVs70y-_X6DFDXuJVRx2rg2lk34EV5X_7YDG5D639qllRbq6E' })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Perform any other necessary action with the token
        localStorage.setItem('firebaseToken', JSON.stringify(currentToken))
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

    const onMessageListener = () =>
      new Promise((resolve) => {
        onMessage(messaging, (payload) => {
          console.log("payload", payload)
          resolve(payload);
        });
    });



export { auth, provider, requestForToken, onMessageListener };


