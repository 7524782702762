import i18n from "i18next";
import { uid } from "react-uid";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import educationImage from "../../../assets/images/landingpage/educationGroup.png";
import img1 from "../../../assets/images/landingpage/education1.png";
import img2 from "../../../assets/images/landingpage/education2.png";
import img3 from "../../../assets/images/landingpage/education3.png";
import img4 from "../../../assets/images/landingpage/education4.png";
import img5 from "../../../assets/images/landingpage/education5.png";
import "./educationSection.css";

const imgArray = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
  {
    img: img5,
  },
];
function EducationSection() {
  const title = {
    fst: i18n.t("home:education.mainTitle.fst"),
    snd: i18n.t("home:education.mainTitle.snd"),
  };
  return (
    <div className="education-section">
    
        <div className="education-content-section">
          <div className="left-section">
            <img src={educationImage} alt="cherri-life-education" />
          </div>
          <div className="slider-section">
            <AliceCarousel
              mouseTracking
              responsive={{
                0: { items: 2 },
                568: { items: 3 },
                1024: { items: 4 },
              }}
              paddingLeft={50}
              paddingRight={50}
              autoPlay
              infinite
              activeIndex={1}
              disableButtonsControls
              animationDuration={1000}
            >
              {imgArray.map((obj) => (
                <div key={uid(obj)} className="img-section">
                  <img src={obj.img} alt="cherri-learn" />
                </div>
              ))}
            </AliceCarousel>
          </div>
          <div className="right-section">
            <div className="title">
              <span>{title.fst}</span>
              <span className="color-FFEB3B">{title.snd}</span>
            </div>
            <div className="des">
              {i18n.t("home:education.subtitle1")} <br />
            </div>
            <div className="button-section">
              <a
                className="bg-color-8D00EE-F96763 button-readmore"
                href="/impact"
              >
                {i18n.t("home:education.buttonName")}
              </a>
            </div>
          </div>
        </div>
    </div>
  );
}

export default EducationSection;
