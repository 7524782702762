const dateConversion = (date) => {
  const today = new Date();
  const dateObj = new Date(date);
  const dayDiff = today.getDate() - dateObj.getDate();
  let string = "";
  if (dayDiff === 0) {
    string = "Today";
  } else if (dayDiff === 1) {
    string = "Yesterday";
  } else {
    string = `${dayDiff} days ago`;
  }

  return string;
};
export default dateConversion;
