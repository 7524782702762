const setJwtTokens = ({ accessToken, refreshToken }) => {
  localStorage.setItem("P_TEACHER_ACCESS_TOKEN", accessToken || "");
  localStorage.setItem("P_TEACHER_REFRESHTOKEN", refreshToken || "");
};

const getJwtTokens = () => {
  const accessToken = localStorage.getItem("P_TEACHER_ACCESS_TOKEN");
  const refreshToken = localStorage.getItem("P_TEACHER_REFRESHTOKEN");
  return { accessToken, refreshToken };
};

const removeJwtTokens = () => {
  localStorage.removeItem("P_TEACHER_ACCESS_TOKEN");
  localStorage.removeItem("P_TEACHER_REFRESHTOKEN");
};

function getAccessToken() {
  return localStorage.getItem("P_TEACHER_ACCESS_TOKEN") || "";
}

function getRefreshToken() {
  return localStorage.getItem("P_TEACHER_ACCESS_TOKEN") || "";
}

export {
  setJwtTokens,
  getJwtTokens,
  getAccessToken,
  getRefreshToken,
  removeJwtTokens,
};
