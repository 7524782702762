import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cherriHoldingMobile } from "../../../assets/images/auth";
import { Footer } from "../../../components";
import MuiButtonSolid from "../../../components/Buttons/MuiButtons";
import { HtmlInput } from "../../../components/InputFields";
import Navbar from "../../../components/Navbar";
import { selectUser } from "../../../features/userSlice";
import { useAuthContext } from "../contexts/AuthContext";
import "./Phone.css";

const NamePassword = () => {
  const user = useSelector(selectUser);

  const navigate = useNavigate();
  const { studentName, setStudentName, password, setPassword } =
    useAuthContext();
  useEffect(() => {
    if (user._id) {
      navigate("/");
    }
  }, [user]);

  const handleClick = async () => {
    navigate("/genderselection");
  };

  return (
    <>
      <Navbar />
      <div className="phone">
        <div className="phoneCotainer">
          <div className="phoneContainerLeft">
            <img
              className="cherriImage"
              src={cherriHoldingMobile}
              alt="cherri Holding Mobile"
            />
          </div>
          <div className="phoneContainerRight">
            <h1>Enter Name and Password</h1>

            <HtmlInput
              margin="0 0 0 0"
              placeholder="Full name"
              value={studentName}
              onChange={(e) => setStudentName(e.target.value)}
              type="text"
            />

            <HtmlInput
              margin="0 0 0 0"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            <MuiButtonSolid
              EndIcon={ArrowRightAltIcon}
              title="Continue"
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => handleClick()}
              size="large"
              fontSize="1rem"
              disabled={false}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NamePassword;
