import React from "react";

const UnLockUnit = ({ hide, handleUnlockUnit }) => (
  <>
    <h3 style={{ marginTop: "2rem" }}>Unlock the Unit with Coins</h3>
    <div
      style={{
        display: "flex",
        width: "90%",
        marginTop: "2rem",
        position: "absolute",
        bottom: "1rem",
      }}
    >
      <button type="button" className="modal-button" onClick={hide}>
        cancel
      </button>
      <button
        type="button"
        className="modal-button"
        onClick={() => handleUnlockUnit()}
      >
        Unlock Unit
      </button>
    </div>
  </>
);

export default UnLockUnit;
