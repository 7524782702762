import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link, useNavigate } from "react-router-dom";
import { appStoreSoonBadge, googlePlayImg1 } from "../../assets/images";
import whiteLogo from "../../assets/images/CHERRILOGO.png";
import "./Footer.css";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="footerContainer">
        <div className="footerLeft">
          <img src={whiteLogo} alt="cherri learn" />
        </div>
        <div className="footerMiddle">
          <div className="footerMiddle__column">
            <h1>COMPANY</h1>
            <Link to="/about">About Us</Link>
            {/* <Link to='/faqs'>FAQs</Link> */}
            <a href="https://cherricart.com/" rel="noreferrer" target="_blank">
              Cherricart
            </a>
            <Link to="/contactUs">Contact Us</Link>
          </div>
          <div className="footerMiddle__column">
            <h1>EXPLORE</h1>
            <a
              href="https://sarvajnafoundation.org/cherrilearn-donate"
              rel="noreferrer"
              target="_blank"
            >
              Sponsor A Child
            </a>
            <Link to="/journey">Our Journey</Link>
          </div>
        </div>
        <div className="footerRight">
          <div className="footerRight__column">
            <h1>Download Our App</h1>
            <div className="footerRight__columnRow">
              <a
                href="https://play.google.com/store/apps/details?id=com.cherrilearn.fun"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={googlePlayImg1} alt="" />
              </a>
            </div>
          </div>
          <div className="footerRight__row">
            <a
              href="https://www.facebook.com/cherrilearnka/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href=" https://www.instagram.com/cherrilearn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://twitter.com/cherrilearn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.youtube.com/channel/UCiwV1j_gqYSfawjF6dYxVEQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon />
            </a>
          </div>
        </div>
      </div>
      <div className="footerBottom">
        <p>©2024 Cherrilearn. All rights reserved.</p>
        <button
          type="button"
          onClick={() => navigate("/privacy-policy")}
          onKeyPress={() => navigate("/privacy-policy")}
        >
          Privacy Policy
        </button>
        <button
          type="button"
          onClick={() => navigate("/terms-conditions")}
          onKeyPress={() => navigate("/terms-conditions")}
        >
          Terms & Conditions
        </button>
      </div>
    </div>
  );
};

export default Footer;
