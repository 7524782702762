import React from "react";
import "./Select.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 140,
    padding: 0,
    borderRadius: 20,
    border: "2px solid #fff",
  },
  select: {
    padding: 0,
    color: "white",
  },
  label: {
    color: "white",
  },
  MenuItem: {},
}));

export default function SelectLabels({ data, value, setValue }) {
  const classes = useStyles();
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={value || data[0].label}
          onChange={handleChange}
          className={classes.select}
        >
          {data.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

// function Select({ defaultValue, data, value, setValue }) {
//   const handleChange = (e) => {
//     setValue(e);
//   };
//   return (
//     <div className='select'>
//       <select value={value} onChange={(e) => handleChange(e.target.value)}>
//         <option selected='selected' hidden='hidden'>
//           {defaultValue || 'select...'}
//         </option>
//         {data.map((item) => (
//           <option key={item.value} value={item.value}>
//             {item.label}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// }

// export default Select;
