import { motion } from "framer-motion/dist/framer-motion";
import "./SetsApart.css";

import {
  centerCircle,
  dashedImage,
  leftStar,
  rightStar,
} from "../../assets/images/Home2";

function SetsApart() {
  return (
    <div className="setsApart">
      <div className="setaApart__headerWrapper">
        <div className="setaApart__header">
          <h1>WHAT MAKES US MORE FUN?</h1>
        </div>
      </div>
      <div className="setsApartContainer__wrapper">
        <div className="setsApartContainer">
          <motion.div
            initial="hidden"
            viewport={{ once: true }}
            whileInView="visible"
            transition={{
              duration: 0.5,

              delay: 0.25,
            }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -70 },
            }}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="setsApartContainerItem">
              <img
                src={dashedImage}
                alt="setsApart"
                className="leftDashedLine"
              />

              <img src={leftStar} alt="star" className="leftImage" />
            </div>
          </motion.div>
          <motion.div
            initial="hidden"
            viewport={{ once: true }}
            whileInView="visible"
            transition={{
              duration: 0.5,
              delay: 0.5,
            }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -70 },
            }}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="setsApartContainerItemCenter">
              <img src={dashedImage} alt="star" className="centerdashedImage" />

              <img src={centerCircle} alt="setsApart" className="centerImage" />
            </div>
          </motion.div>
          <motion.div
            initial="hidden"
            viewport={{ once: true }}
            whileInView="visible"
            transition={{
              duration: 0.5,

              delay: 0.75,
            }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -70 },
            }}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="setsApartContainerItem">
              <img src={dashedImage} alt="star" className="rightDashedLine" />

              <img src={rightStar} alt="setsApart" className="rightImage" />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default SetsApart;
