import React from "react";
import Select from "react-select";
import "./ReactSelect.css";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    width: "100%",
  }),
  option: (provided) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    textAlign: "center",
    fontFamily: "font-family: 'Roboto', sans-serif;",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    border: "1px solid #c0c0c0",
    background: "#fff",
    display: "flex",
    borderRadius: "7px",
    width: "100%",
    cursor: "pointer",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const SelectClassBarWithDefault = ({ data, setValue, defaultValue, isSearchable, placeholder }) => {
  const handleSelect = (e) => {
    setValue(e.value);
  };

  return (
    <Select
      className="ReactSelect"
      options={data}
      menuPlacement="auto"
      menuPosition="fixed"
      placeholder={placeholder || 'Select...'}
      value={
        defaultValue
          && { value: defaultValue, label: defaultValue }
          // : { value: "select...", label: "select..." }
      }
      styles={customStyles}
      onChange={handleSelect}
      isSearchable= {isSearchable}
    />
  );
};

export default SelectClassBarWithDefault;
