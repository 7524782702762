import {
  bl,
  blLogo,
  deccan,
  deccanLogo,
  prajavani,
  prajavaniLogo,
  thehindu,
  thehinduLogo,
} from "../../../assets/images";

const newsArticles = [
  {
    image: prajavani,
    newsUrl:
      "https://www.prajavani.net/news/karnataka-news/cherrilearn-app-to-help-kannada-primary-school-children-970046.html",
    newsLogo: prajavaniLogo,
  },
  {
    image: thehindu,
    newsUrl:
      "https://www.thehindu.com/news/national/rbi-wins-businessline-changemaker-of-the-year-award-amul-is-crowned-iconic-changemaker/article67495082.ece",
    newsLogo: thehinduLogo,
  },
  {
    image: deccan,
    newsUrl: "https://23in23.deccanherald.com/shrinidhi-r-s",
    newsLogo: deccanLogo,
  },
  {
    image: bl,
    newsUrl:
      "https://www.thehindubusinessline.com/multimedia/video/2023-winner-young-changemaker-shrinidhi-rs/article67500587.ece",
    newsLogo: blLogo,
  },
];
export default newsArticles;
