import useMediaQuery from "@mui/material/useMediaQuery";
import { nanoid } from "nanoid";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import i18n from "../../../i18";
import "./InTheNews.css";
import NewsCard from "./NewsCard/NewsCard";
import newsArticles from "./data";

const InTheNews = () => {
  const mobile = useMediaQuery("(max-width:600px)");

  const items = newsArticles.map((news) => (
    <NewsCard key={nanoid()} news={news} />
  ));
  return (
    <>
      {items && (
        <div className="newsCarouselContainer">
          <h1 className="carouselTitle">
            <p>
              <span> {i18n.t("home:inTheNewsSection.title1")}:</span>{" "}
              {i18n.t("home:inTheNewsSection.title2")}
            </p>
          </h1>

          <div className="aliceCarouselWrapper">
            <AliceCarousel
              mouseTracking={mobile}
              items={items}
              disableButtonsControls={mobile}
              autoPlay={mobile}
              autoPlayInterval={1000}
              responsive={{
                0: { items: 1 },
                568: { items: 2 },
                900: { items: 3 },
                1300: { items: 4 },
              }}
              activeIndex={0}
              infinite
              animationDuration={1000}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default InTheNews;
