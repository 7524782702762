import { makeStyles } from "@mui/styles";

export default makeStyles({
  parentWrapper: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  playerWrapper: {
    width: "100%",
    "@media (max-width:960px)": {
      width: "90%",
    },
    "@media (max-width:550px)": {
      width: "98%",
    },
    position: "relative",
  },
  playerContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    borderRadius: "5px",
  },
  reactPlayer: {
    borderRadius: "20px",
    overflow: "hidden",
  },
  controlsPlayer: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    borderRadius: "20px",
    backgroundColor: "rgba(0,0,0,0.6)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: "1",
    width: "100%",
  },
  playerTitle: {
    color: "#fff",
  },
  settingsIcon: {
    color: "#fff",
    cursor: "pointer",
  },
  middleControls: {
    direction: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 50,
  },
  controlIcon: {
    color: "#777",
    cursor: "pointer",
    fontSize: 50,
    "@media (max-width:960px)": {
      fontSize: 30,
    },
    "@media (max-width:500px)": {
      fontSize: 30,
    },
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },
  bottomControls: {
    direction: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    "@media (max-width:960px)": {
      padding: "0 20px 10px 20px",
    },
    "@media (max-width:550px)": {
      padding: "0 5px 10px 5px",
    },
  },
  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "#fff",
    },
    fontSize: 40,
    "@media (max-width:960px)": {
      fontSize: 30,
    },
    "@media (max-width:550px)": {
      fontSize: 20,
    },
  },
  bottomLeftControls: {
    display: "flex",
    alignItems: "center",
  },
  volumeSlider: {
    width: 80,
    fontSize: 40,
    "@media (max-width:960px)": {
      width: 80,
    },
    "@media (max-width:550px)": {
      width: 60,
    },
  },
  timer: {
    color: "#999",
    marginLeft: "1rem",
  },
  activeRate: {
    backgroundColor: "#3F51B5",
    "&:hover": {
      backgroundColor: "#3F51B5",
    },
  },
  PrettoSlider: {
    padding: "0px",
  },
});
