import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAppConfig } from "../apis";

export const fetchAppConfig = createAsyncThunk(
  "appConfig/fetchAppConfig",
  async () => {
    const response = await getAppConfig();
    return response.data;
  }
);

export const appConfigSlice = createSlice({
  name: "appConfig",
  initialState: {
    appConfig: {},
  },
  reducers: {
    setAppConfig: (state, action) => {
      state.appConfig = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchAppConfig.fulfilled, (state, action) => {
      // assign config to the state object
      state.appConfig = action.payload;
    });
  },
});

export const selectAppConfig = (state) => state.appConfig.appConfig;

export default appConfigSlice.reducer;
