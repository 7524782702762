import i18n from "i18next";
import { withTranslation } from "react-i18next";
import cherrilearnlogo from "../../../assets/images/CHERRILOGO.png";
import footer1 from "../../../assets/images/landingpage/footer1New.png";
import footer2 from "../../../assets/images/landingpage/footer2New.png";

import a from "./a.svg";
import "./footer.css";

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-main-section">
        <div className="f1">
          <img src={footer1} alt="cherri-learn-footer" />
        </div>
        <div className="center-section">
          <div className="img-div">
            <img src={cherrilearnlogo} alt="cherri-learn-logo" />
          </div>
          <div className="des-section color-FFFFFF">
            {i18n.t("home:footerSection.title")}
          </div>
          <div className="sub-des-section color-FFFFFF">
            {i18n.t("home:footerSection.Downloadtoday")}
          </div>
          <div className="link-button-section">
            <div className="link-button">
              <a
                href="https://play.google.com/store/apps/details?id=com.cherrilearn.fun"
                target="new"
              >
                <div className="bg-color-black button-section color-FFFFFF ">
                  <div className="icon-div">
                    <img src={a} alt="" />
                  </div>
                  <div className="content">
                    <div className="con-sub-2-title">
                      {i18n.t("home:footerSection.GETINON")}
                    </div>
                    <div className="con-sub-title">
                      {i18n.t("home:footerSection.GooglePlay")}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="f3">
          <img src={footer2} alt="cherri-learn-footer2" />
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(Footer);
