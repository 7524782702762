import React from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// assets
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { cherriHoldingMobile } from "../../../assets/images/auth";
import MuiButtonSolid from "../../../components/Buttons/MuiButtons";

import { signUpWithEmail } from "../../../apis";
import {
  Footer,
  HtmlInput,
  SelectClassBarWithDefault,
} from "../../../components";
import Navbar from "../../../components/Navbar";
import {
  getDistrictsByState,
  stateData,
} from "../../SignUp/SignUpContainer/data";
import { useAuthContext } from "../contexts/AuthContext";
import "./Location.css";
import { setUser } from "../../../features/userSlice";

import { getDeviceId, setJwtTokens } from "../../../utils";

const Location = () => {
  const {
    studentName,
    phone,
    password,
    gender,
    email = "",
    grade,
    state,
    setState,
    referralCode = "",
    district,
    setDistrict,
    board,
    schoolName = "",
    taluk,
    setTaluk,
  } = useAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handleRegister = async () => {
    const deviceId = await getDeviceId();
    const userData = {
      studentName,
      phone,
      password,
      gender,
      grade,
      email,
      board,
      schoolName,
      state,
      district,
      taluk,
      isPhoneVerified: true,
      deviceId,
      deviceModel: "*",
    };

    const firebaseToken = localStorage.getItem("firebaseToken");
    if (firebaseToken) userData.firebaseToken = firebaseToken;
    if (referralCode) userData.referralCode = referralCode;

    const response = await signUpWithEmail(userData);
    console.log(response);

    if (response?.data.success) {
      toast.success("Sigup successful");

      dispatch(setUser(response.data.profile));
      setJwtTokens(response.data.tokenData);
      navigate("/dashboard");
    } else {
      // setError(`*${response?.data.message}`);
    }
  };
  return (
    <>
      <Navbar />
      <div className="location">
        <div className="locationCotainer">
          <div className="locationContainerLeft">
            <img
              className="cherriImage"
              src={cherriHoldingMobile}
              alt="cherri Holding Mobile"
            />
          </div>
          <div className="locationContainerRight">
            <h1>Enter Location Details</h1>
            <div className="autocomplete">
              <SelectClassBarWithDefault
                placeholder="State"
                data={stateData}
                defaultValue={state}
                setValue={setState}
                isSearchable
              />
              <SelectClassBarWithDefault
                placeholder="District"
                data={getDistrictsByState(state)}
                defaultValue={district}
                setValue={setDistrict}
                isSearchable
              />
              <HtmlInput
                placeholder="Taluk"
                maxLength={100}
                type="text"
                value={taluk}
                onChange={(e) => setTaluk(e.target.value)}
              />
            </div>
            <div className="submit__button">
              <MuiButtonSolid
                EndIcon={ArrowRightAltIcon}
                title="Continue"
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleRegister}
                size="large"
                fontSize="1rem"
                disabled={false}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Location;
