import axios from "../axios/Axios";

const fetchUnits = async ({grade, board, medium, subject}) => {
  console.log({grade, board, medium, subject})
  try {
    return await axios({
      method: "get",
      url: `/v1/auth/subjects/${subject}/units?grade=${grade}&board=${board}&medium=${medium}`,
    });
  } catch (error) {
    return error.response;
  }
};

const unLockUnit = async (unitId) => {
  try {
    return await axios({
      method: "post",
      url: `/v1/auth/payment/unlock/unit`,
      data: { unitId },
    });
  } catch (error) {
    return error.response;
  }
};

const fetchUnlockedUnits = async () => {
  try {
    return await axios({
      method: "get",
      url: `/v1/auth/profile/unlocked-units`,
    });
  } catch (error) {
    return error.response;
  }
};

export default fetchUnits;

export { unLockUnit, fetchUnlockedUnits };
