import React from "react";
import "./PopOver.css";
import { uid } from "react-uid";
import { useSelector } from "react-redux";
import { useNavigate} from "react-router-dom";
import { dateConversion } from "../../utils";
import { selectUser } from "../../features/userSlice";

function PopOver({ open, data }) {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  return (
    open && (
      <div className="popUpContainer">
        <div className="popUpContainer__box">
          {data.map((item) => (
            <div
              key={uid(item)}
              className="notification__row"
              onClick={() => navigate(`/`)}
              onKeyPress={() => navigate(`/`)}
              role="button"
              tabIndex="0"
            >
              <img src={item.imageUrl} alt="n-img" className="skeleton" />
              <div className="notification__rowItem">
                <h2>{item.title}</h2>
                <p>
                  <strong>Hey {user.studentName}, </strong> {item.content}
                </p>
                <span>{dateConversion(item.timestamp)}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
}

export default PopOver;
