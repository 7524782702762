/* eslint-disable no-unused-vars */
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate} from "react-router-dom";
import { lose, starTrophy } from "../../../assets/images/activity";
import { ReactConfetti } from "../../../pages/Activities/Components";

function ActivityScore({ activityScore, totalScore, hide }) {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    containerImg: {
      width: "10rem",
      height: "10rem",
      objectFit: "contain",
    },
    containerText: {
      fontSize: "1.5rem",
      margin: "10px 0",
      color: "#A310FD",
    },
    containerButton: {
      width: isMobile ? "8rem" : "10rem",
      padding: isMobile ? "10px 10px" : "10px 30px",
      background: "linear-gradient(267.1deg, #A310FD -7.31%, #D311FD 109.39%)",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
      borderRadius: "50px",
      color: "#fff",
    },
    buttonsContianer: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
  };

  const getImage = () => {
    if (totalScore / 2 <= activityScore) {
      return starTrophy;
    }
    return lose;
  };

  const navigate= useNavigate();

  return (
    <div style={styles.container}>
      <img style={styles.containerImg} src={getImage()} alt="congrats" />
      <h2 style={styles.containerText}>
        {" "}
        {activityScore} / {totalScore}
      </h2>
      <div className={styles.buttonsContianer}>
        <button
          onClick={() => hide()}
          type="button"
          style={styles.containerButton}
        >
          Close
        </button>
        <button
          onClick={() => navigate(-1)}
          type="button"
          style={styles.containerButton}
        >
          Continue
        </button>
      </div>
      {totalScore / 2 <= activityScore && <ReactConfetti />}
    </div>
  );
}

export default ActivityScore;
