import "./NewsCard.css";

const NewsCard = ({ news }) => (
  <a href={news.newsUrl} target="_blank" rel="noreferrer">
    <div className="newsCard">
      <div className="newsBody">
        <img src={news.image} alt="news" />
      </div>

      <div className="newsLogo">
        <img src={news.newsLogo} alt="" />
      </div>
    </div>
  </a>
);

export default NewsCard;
