import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Gender from "../pages/Auth/genderselection";
import Location from "../pages/Auth/location";
import Otp from "../pages/Auth/otp/Otp";
import OtpLogin from "../pages/Auth/otplogin/Otp";
import MainPage from '../MainPage'
import EnterPassword from "../pages/Auth/password";
import Phone from "../pages/Auth/phone";
import SelectClass from "../pages/Auth/selectclass";
import NamePassword from "../pages/Auth/namepass";

import { Loader } from "../utils";
import PrivateRoute from "./Private.route";

const About = lazy(() => import("../pages/About"));
const ContactUs = lazy(() => import("../pages/ContactUs"));
const Mcq = lazy(() => import("../pages/Mcq"));
const FillInTheBlanks = lazy(() => import("../pages/FillInTheBlanks"));
const Classification = lazy(() => import("../pages/Classification"));
const MatchTheFollowing = lazy(() => import("../pages/MatchTheFollowing"));
const Unit = lazy(() => import("../pages/Unit"));
const Chapters = lazy(() => import("../pages/Chapters"));
const Topics = lazy(() => import("../pages/Topics"));
const UserProfile = lazy(() => import("../pages/UserProfile"));
const Terms = lazy(() => import("../pages/Terms"));
const Policy = lazy(() => import("../pages/Policies"));
// const SignUp = lazy(() => import("../pages/SignUp"));
const OtpPage = lazy(() => import("../pages/Otp"));
const NewPassword = lazy(() => import("../pages/NewPassword"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
// const SignUpDetails = lazy(() => import("../pages/SignUpDetails"));
const Activity = lazy(() => import("../pages/Activities"));
const PaymentPlans = lazy(() => import("../pages/PaymentPlans"));

const Journey = lazy(() => import("../pages/Journey"));

function AppRoutes() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="sweet-loading">
            <Loader />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<MainPage />} />
          {/* <Route path="/register" element={<SignUp />} />
          <Route path="/signupdetails" element={<SignUpDetails />} /> */}
          <Route path="/forgot-password" element={<OtpPage />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/genderSelection" element={<Gender />} />
          <Route path="/password" element={<EnterPassword />} />
          <Route path="/location" element={<Location />} />
          <Route path="/namepassword" element={<NamePassword />} />
          <Route path="/about" element={<About />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/otplogin" element={<OtpLogin />} />
          <Route path="/login" element={<Phone />} />
          <Route path="/selectclass" element={<SelectClass />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route
            path="/:subject/units"
            element={<PrivateRoute component={Unit} />}
          />
          <Route
            path="/chapters"
            element={<PrivateRoute component={Chapters} />}
          />
          <Route path="/topics" element={<PrivateRoute component={Topics} />} />
          <Route
            path="/:topicId/activity"
            element={<PrivateRoute component={Activity} />}
          />
          <Route
            path="/paymentPlans"
            element={<PrivateRoute component={PaymentPlans} />}
          />
          <Route path="/fillintheblanks" element={<FillInTheBlanks />} />
          <Route path="/mcq" element={<Mcq />} />
          <Route path="/matchthefollowing" element={<MatchTheFollowing />} />
          <Route path="/classification" element={<Classification />} />
          <Route path="/loader" element={<Loader />} />
          <Route
            path="/user-profile"
            element={<PrivateRoute component={UserProfile} />}
          />
          <Route
            path="/dashboard"
            element={<PrivateRoute component={Dashboard} />}
          />
          <Route path="/journey" element={<Journey />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default AppRoutes;