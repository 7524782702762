import React from "react";
import "./HeaderTitle.css";
import TimerIcon from "@mui/icons-material/Timer";
import { questionBro } from "../../../assets/images";

function HeaderTitle({ counter, heading, instruction }) {
  return (
    <div className="headerTitle">
      <div className="headerTitleBg">
        <div className="headerTitleContainer">
          <div className="headerTitleContainer__title">
            <h1>Activity</h1>
            <h2>{heading.title}</h2>
          </div>
          <img
            src={heading.headingImage ? heading.headingImage : questionBro}
            alt="heading__image"
          />
        </div>
      </div>
      <div className="headerComponents">
        <div className="headerComponents__container">
          <h3>Real Number</h3>
        </div>
        <div className="headerComponents__container">
          <h3>Unit 4 : The Jungle Fight</h3>
        </div>
        <div className="headerComponents__container">
          <TimerIcon />
          <h3>
            Timer : <span> {counter} </span>
          </h3>
        </div>
      </div>
      <div className="instruction">
        <div className="instructionContainer">
          <h3>Instruction</h3>
          <p>{instruction.title}</p>
        </div>
      </div>
    </div>
  );
}

export default HeaderTitle;
