import axios from '../axios/Axios';

const verifyPayment = async (data) =>
  axios({
    method: 'post',
    url: `v1/auth/payment/verify`,
    data,
  });

const fetchSubscriptions = async (data) =>
  axios({
    method: 'get',
    url: `v1/auth/profile/subscription-plans?grade=${data.grade}&board=${data.board}&medium=${data.medium}`,
  });

const generateOrderId = async (data) =>
  axios({
    method: 'post',
    url: `v1/auth/payment/orderId`,
    data,
  });

export default verifyPayment;

export { fetchSubscriptions, generateOrderId };
