import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Avatar, Badge, IconButton } from "@mui/material";
import i18n from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CssButtonOutline, CssButtonSolid } from "..";
import { fetchNotifications } from "../../apis";
import { coin } from "../../assets/images";
import cherrilearnlogo from "../../assets/images/CHERRILOGO.png";
import { selectUser } from "../../features/userSlice";
import { generateDiceBearAvatar } from "../../utils";
import "./Navbar.css";
import PopOver from "./PopOver";

const languages = [
  {
    label: "English",
    value: "English",
  },
  {
    label: "ಕನ್ನಡ",
    value: "ಕನ್ನಡ",
  },
];

const customStyles = {
  input: (baseStyles) => ({
    ...baseStyles,
    color: "transparent",
  }),
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    color: isSelected ? "white" : "#000000",
    cursor: isDisabled ? "not-allowed" : "default",
  }),
};

const Navbar = ({ home, color }) => {
  function getBestFontColor(bgColor) {
    if (bgColor) {
      const r = parseInt(bgColor.substr(1, 2), 16);
      const g = parseInt(bgColor.substr(3, 2), 16);
      const b = parseInt(bgColor.substr(5, 2), 16);

      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

      if (luminance > 0.5) return "black";
      return "white";
    }
    return "white";
  }

  const fontColor = getBestFontColor(color);
  const user = useSelector(selectUser);
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedLang, setSelectedLang] = useState("English");

  const navigate = useNavigate();

  const changeLanguageToggle = (e) => {
    setSelectedLang(e);
    if (e === "English") {
      i18n.changeLanguage("en");
    }
    if (e === "ಕನ್ನಡ") {
      i18n.changeLanguage("kn");
    }
  };

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  const handleLogin = (mobile) => {
    if (mobile) closeMobileMenu();
    navigate("/login");
  };


  const homeStyles = {
    color: fontColor,
    backgroundColor: color || "#760A98",
  };

  useEffect(() => {
    if (user._id) {
      (async () => {
        const result = await fetchNotifications(
          user.grade,
          user.board,
          user.medium
        );
        setNotifications(result.data);
      })();
    }
  }, [user._id]);

  return (
    <>
      <nav className="navbar" style={homeStyles}>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={cherrilearnlogo} alt="logo" />
          </Link>
          <div
            className="menu-icon"
            onClick={handleClick}
            onKeyUp={handleClick}
            role="button"
            tabIndex="0"
          >
            {click ? (
              <CloseIcon fontSize="large" />
            ) : (
              <MenuIcon fontSize="large" />
            )}
          </div>
          <ul
            style={homeStyles}
            className={click ? "nav-menu active" : "nav-menu"}
          >
            {!home ? (
              <li className="nav-item">
                <Link
                  to="/journey"
                  className="nav-links"
                  onClick={closeMobileMenu}
                  style={homeStyles}
                >
                  Our Journey
                </Link>
              </li>
            ) : (
              ""
            )}
            {!home ? (
              <>
                <li className="nav-item">
                  <a
                    href="https://sarvajnafoundation.org/cherrilearn-donate"
                    rel="noreferrer"
                    target="_blank"
                    className="nav-links"
                    onClick={closeMobileMenu}
                    style={homeStyles}
                  >
                    Sponsor A Child
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    to="/contactUs"
                    className="nav-links"
                    onClick={closeMobileMenu}
                    style={homeStyles}
                  >
                    Contact Us
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/about"
                    className="nav-links"
                    onClick={closeMobileMenu}
                    style={homeStyles}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    href="https://cherricart.com/"
                    rel="noreferrer"
                    target="_blank"
                    className="nav-links"
                    onClick={closeMobileMenu}
                    style={homeStyles}
                  >
                    Cherricart
                  </a>
                </li>

                <li className="nav-item">
                  <Select
                    className="ReactSelect"
                    options={languages}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    placeholder="Select..."
                    onClick={closeMobileMenu}
                    styles={customStyles}
                    onChange={(e) => changeLanguageToggle(e.value)}
                    value={
                      selectedLang
                        ? { value: selectedLang, label: selectedLang }
                        : { value: "English", label: "English" }
                    }
                  >
                    Language
                  </Select>
                </li>
              </>
            ) : (
              ""
            )}

            {user._id ? (
              <>
                <Link
                  to="/user-profile"
                  className="nav-links-mobile"
                  style={homeStyles}
                >
                  My Account
                </Link>
                <li>
                  <Link
                    to="/"
                    className="nav-links-mobile"
                    onClick={closeMobileMenu}
                    style={homeStyles}
                  >
                    Logout
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/login" className="nav-links-mobile">
                    Login
                  </Link>
                </li>
            
              </>
            )}
          </ul>
          {button &&
            (user._id ? (
              <div className="navbarUser__details">
                {/* <p style={homeStyles}>My learning</p> */}
                <div className="notification__popup">
                  <IconButton onMouseOver={() => setOpenNotification(true)}>
                    <Badge badgeContent={notifications.length} color="primary">
                      <NotificationsIcon style={homeStyles} />
                    </Badge>
                  </IconButton>
                  {openNotification && (
                    <div
                      className="popOverContainer"
                      onMouseLeave={() => setOpenNotification(false)}
                    >
                      <PopOver open={openNotification} data={notifications} />
                    </div>
                  )}
                </div>
                {user.totalCoinsEarned && (
                  <div className="coinsEarned">
                    <img src={coin} alt="coin" />
                    <p>{user.totalCoinsEarned}</p>
                  </div>
                )}
                <Avatar
                  src={
                    user.photo
                      ? user.photo
                      : generateDiceBearAvatar(user.gender, user.studentName)
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/user-profile")}
                />
              </div>
            ) : (
              <div className="navbar__buttons">
                <CssButtonSolid
                  title="Login"
                  backgroundColor="#ffffff"
                  textColor={!home ? "#000" : "#3f51b5"}
                  margin="0 1rem 0 0"

                  padding="7px 30px"
                  fontSize="1rem"
                  onClick={() => handleLogin(false)}
                />
           
              </div>
            ))}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
