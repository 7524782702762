import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import {
  bookIcon,
  comment,
  sandClockIcon,
} from '../../../../assets/images/activity';
import {
  selectActiveTopic,
  selectActiveUnit,
} from '../../../../features/unitsSlice';
import { back } from '../../../../assets/images/Home2';
import './Header.css';

function Header({ activity, counter }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const activeTopic = useSelector(selectActiveTopic);
  const activeUnit = useSelector(selectActiveUnit);

  const navigate = useNavigate();

  return (
    <div className='header'>
      <div className='headerContainer'>
        {!isMobile && (
          <div
            role='button'
            tabIndex={0}
            onClick={() =>navigate(-1)}
            onKeyDown={() =>navigate(-1)}
            className='activityContainer__top-backButton'
          >
            <img src={back} alt='back' />
          </div>
        )}
        <div className='activityHeaderTitle'>
          <img src={comment} alt='comment' />
          <div className='activityHeaderTitleCenter'>
            <h1>Activity</h1>
            <h3>{activity?.activity?.heading?.title}</h3>
          </div>
          <img src={comment} alt='comment' />
        </div>
        <div className='headerContent'>
          <div className='headerContentItem'>
            <img src={bookIcon} alt='book' />
            <p>{activeTopic.topicName}</p>
          </div>
          <div className='headerContentItem'>
            <p>Unit : {activeUnit.unitNumber}</p>
            <p>{activeUnit.unit.name}</p>
          </div>
          <div className='headerContentItem'>
            <img src={sandClockIcon} alt='book' />
            <p>Timer : {counter}</p>
          </div>
        </div>
      </div>
      {activity.activity.instruction?.title && (
        <div className='headerContent__instruction'>
          <span />
          <div className='headerContent__instructionRight'>
            <h3>Instruction</h3>
            <p>{activity.activity.instruction.title}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
