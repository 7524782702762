import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { userCheckIfExists } from "../../../apis/auth";
import { cherriHoldingMobile } from "../../../assets/images/auth";
import { Footer } from "../../../components";
import MuiButtonSolid from "../../../components/Buttons/MuiButtons";
import { HtmlInput } from "../../../components/InputFields";
import Navbar from "../../../components/Navbar";
import { selectUser } from "../../../features/userSlice";
import { useAuthContext } from "../contexts/AuthContext";
import "./Phone.css";

const Phone = () => {
  const user = useSelector(selectUser);

  const navigate = useNavigate();
  const { phone, setPhone, setOtpSecret } = useAuthContext();
  useEffect(() => {
    if (user._id) {
      navigate("/");
    }
  }, [user]);

  const handleClick = async () => {
    if(phone.length !==10) return toast.error("Invalid phone number")
    const response = await userCheckIfExists({
      phone,
      appSignature: "cherri-learn",
    });
    if (response?.data.accountExists) {
      navigate("/password");
    } else {
      setOtpSecret(response.data.otpSecret);
      navigate("/otp");
    }
  };

  return (
    <>
      <Navbar />
      <div className="phone">
        <div className="phoneCotainer">
          <div className="phoneContainerLeft">
            <img
              className="cherriImage"
              src={cherriHoldingMobile}
              alt="cherri Holding Mobile"
            />
          </div>
          <div className="phoneContainerRight">
            <h1>Login into Cherrilearn</h1>
            <p>Enter your Phone Number</p>
            <HtmlInput
              margin="2rem 0 0 0"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="text"
            />
            <MuiButtonSolid
              EndIcon={ArrowRightAltIcon}
              title="Continue"
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => handleClick()}
              size="large"
              fontSize="1rem"
              disabled={false}
            />

            <div className="phoneContainerRightBottom">
              <p>
                <strong>By Continuing, you agree to CherriLearn</strong>
              </p>
              <div className="marginer" />
              <p>
                <Link to="/terms-conditions">Terms and Conditions</Link> &{" "}
                <Link to="/terms-conditions">Privacy Policy</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Phone;
