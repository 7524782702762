import React from "react";

function Marginer({ dotColor, lineColor }) {
  const styles = {
    marginer: {
      margin: "10px 0",
      gap: "5px",
      display: "flex",
      alignItems: "center",
    },
    dot: {
      width: "10px",
      height: "10px",
      backgroundColor: dotColor || "#fff",
      borderRadius: "50%",
    },
    line: {
      width: "100px",
      height: "4px",
      backgroundColor: lineColor || "#fff",
      borderRadius: "20px",
    },
  };

  return (
    <div className="marginer" style={styles.marginer}>
      <span className="dot" style={styles.dot} />
      <span className="line" style={styles.line} />
    </div>
  );
}

export default Marginer;
