import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {},
    hasPremiumAccess: false,
    hasVavAccess: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLogout: (state, action) => {
      state.user = action.payload;
      state.hasPremiumAccess = false;
      state.hasVavAccess = false;
    },
    setPremiumAccess: (state, action) => {
      state.hasPremiumAccess = action.payload;
    },
    setVavAccess: (state, action) => {
      state.hasVavAccess = action.payload;
    },
  },
});

export const { setUser, setLogout, setPremiumAccess, setVavAccess } =
  userSlice.actions;

export const selectPremiumAccess = (state) => state.user.hasPremiumAccess;

export const selectVavAccess = (state) => state.user.hasVavAccess;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
