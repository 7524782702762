import { motion } from "framer-motion/dist/framer-motion";
import i18n from "i18next";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { uid } from "react-uid";
import activityImg from "../../../../assets/images/landingpage/cardSection1.png";
import GroupImg2 from "../../../../assets/images/landingpage/Group2.png";
import GroupImg3 from "../../../../assets/images/landingpage/Group3.png";

import "./cardSection.css";

function CardSection() {
  const cardArray = [
    {
      svg: activityImg,
      number: i18n.t("home:whySection.cardArray.0.number"),
      des: i18n.t("home:whySection.cardArray.0.des"),
    },
    {
      svg: GroupImg2,
      number: i18n.t("home:whySection.cardArray.1.number"),
      des: i18n.t("home:whySection.cardArray.1.des"),
    },
    {
      svg: GroupImg3,
      number: i18n.t("home:whySection.cardArray.2.number"),
      des: i18n.t("home:whySection.cardArray.2.des"),
    },
  ];
  const title = {
    first: i18n.t("home:whySection.mainTitle.first"),
    secound: i18n.t("home:whySection.mainTitle.secound"),
  };
  return (
    <div className="card-section">
      <div className="title">
        <p>
          <span className="color-black">{title.first}</span>
          <span className="span-text-color">{title.secound}</span>
        </p>
      </div>
      <div className="card-content slider">
        {/* <Carousel> */}
        <AliceCarousel
          mouseTracking
          responsive={{
            0: { items: 1 },
            568: { items: 1 },
            1024: { items: 1 },
          }}
          // paddingLeft={50}
          // paddingRight={50}
          autoPlay
          infinite
          activeIndex={1}
          animationDuration={1000}
        >
          {cardArray.map((obj) => (
            <div className="card" key={uid(obj)}>
              <div className="image-section">
                <img src={obj.svg} alt="" />
              </div>
              <div className="title-section" title={obj.number}>
                {obj.number}
              </div>
              <div className="des-section">{obj.des}</div>
            </div>
          ))}
        </AliceCarousel>
        {/* </Carousel> */}
      </div>
      <div className="card-content display-slider-card">
        {cardArray.map((obj, index) => (
          <motion.div
            initial="hidden"
            viewport={{ once: true }}
            whileInView="visible"
            transition={{
              duration: 0.5,

              delay: 0.25 * index,
            }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="card" key={uid(obj)}>
              <div className="image-section">
                <img src={obj.svg} alt="" />
              </div>
              <div className="title-section" title={obj.number}>
                {obj.number}
              </div>
              <div className="des-section">{obj.des}</div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default CardSection;
