import i18n from "i18next";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { uid } from "react-uid";
import bottom from "../../../assets/images/landingpage/bgslider.png";
import bgImg1 from "../../../assets/images/landingpage/pngwing1.png";
import bgImg2 from "../../../assets/images/landingpage/pngwing2.png";
import bgImg3 from "../../../assets/images/landingpage/pngwing3.png";
import bgImg4 from "../../../assets/images/landingpage/pngwing4.png";
import "./Scroller.css";

function Scroller() {
  const sliderArray = [
    {
      img: bgImg4,
      bottomBg: "bg-color-E122E5-A4E522",
      title: i18n.t("home:sliderSection.3.title"),
    },
    {
      img: bgImg2,
      bottomBg: "bg-color-00BCD4",
      title: i18n.t("home:sliderSection.1.title"),
    },

    {
      img: bgImg1,
      bottomBg: "bg-color-1AC4FA-A025ED",
      title: i18n.t("home:sliderSection.2.title"),
    },

    {
      img: bgImg3,
      bottomBg: "bg-color-4DD0E1",
      title: i18n.t("home:sliderSection.4.title"),
    },
  ];
  const title = {
    fst: i18n.t("home:sliderSection.mainTitle.fst"),
    snd: i18n.t("home:sliderSection.mainTitle.snd"),
  };
  return (
    <div className="scroller-section">
      <div className="title">
        <span className="color-black">{title.fst}</span>
        <span className="span-text-color">{title.snd}</span>
      </div>
      <div className="slider-content">
        <div className="bg-color-F5FEFF slider-section color-FFFFFF">
          <div className="slider-top-section">
            <div className="top-section">
              <div className="title">
                {i18n.t("home:sliderSection.Subject")}
              </div>
            </div>
          </div>
          <div className="slider-carousel">
            <AliceCarousel
              mouseTracking
              responsive={{
                0: { items: 1 },
                568: { items: 2 },
                1024: { items: 3 },
              }}
              activeIndex={1}
              paddingLeft={50}
              paddingRight={50}
              autoPlay
              infinite
              disableButtonsControls
              animationDuration={1000}
            >
              {sliderArray.map((obj) => (
                <div className="main-div" key={uid(obj)}>
                  <div className="img-section">
                    <img src={obj.img} alt="bg-slider" />
                  </div>
                  <div className={`bottom-section ${obj.bottomBg}`}>
                    <div className="title">{obj.title}</div>
                  </div>
                </div>
              ))}
            </AliceCarousel>
          </div>
          <div className="image-section">
            <img src={bottom} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scroller;
