import i18n from "i18next";
import { Facebook, Instagram, Linkedin, Twitter } from "react-feather";
import { Link } from "react-router-dom";
import footerBg from "../../../assets/images/landingpage/footerbottom.png";
import "./footerLink.css";

function FooterLink() {
  return (
    <div className="footer-link-section bg-color-A212FF">
      <div className="footer-link-content">
        <div className="link-section">
          <div className="link-div color-FFFFFF">
            <Link to="/about" className="link-div color-FFFFFF">
              {i18n.t("home:footerLink.AboutUs")}
            </Link>
          </div>
          {/* <div className="dot" />
          <div className="link-div color-FFFFFF">
            {i18n.t("home:footerLink.Careers")}
          </div> */}
          <div className="dot" />
          <Link to="/privacy-policy" className="link-div color-FFFFFF">
            {i18n.t("home:footerLink.PrivacyPolicy")}
          </Link>
          <div className="dot" />
          <Link to="/terms-conditions" className="link-div color-FFFFFF">
            {i18n.t("home:footerLink.TermsConditions")}
          </Link>
          <div className="dot" />
          <Link to="/contactUs" className="link-div color-FFFFFF">
            {i18n.t("home:footerLink.ContactUs")}
          </Link>
        </div>
        <div className="social-section">
          <a
            href="https://twitter.com/cherrilearn"
            target="new"
            className="link-div"
          >
            <Twitter />
          </a>

          <a
            href="https://www.linkedin.com/company/cherrilearn/"
            target="new "
            className="link-div"
          >
            <Linkedin />
          </a>

          <a
            className="insta-link"
            href="https://www.instagram.com/cherrilearn/"
            target="new"
          >
            <Instagram />
          </a>

          <a
            className="insta-link"
            href="https://www.facebook.com/cherrilearnka/"
            target="new"
          >
            <Facebook />
          </a>
        </div>
      </div>
      <div className="image-section">
        <img src={footerBg} alt="cherri-learn-footer2" />
      </div>
    </div>
  );
}
export default FooterLink;
