import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import HomeEn from "./Locales/en/Home.json";
import HomeKn from "./Locales/kn/Home.json";
import ContactEn from "./Locales/en/Contact.json";
import ContactKn from "./Locales/kn/Contact.json";
import PrivEn from "./Locales/en/Privacy.json";
import PrivKn from "./Locales/kn/Privacy.json";
import TermsEn from "./Locales/en/Terms.json";
import TermsKn from "./Locales/kn/Terms.json";

const resources = {
  en: {
    home: HomeEn,
    con: ContactEn,
    privacy: PrivEn,
    terms: TermsEn,
  },
  kn: {
    home: HomeKn,
    privacy: PrivKn,
    con: ContactKn,
    terms: TermsKn,
  },
};
i18n
  //   .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    debug: false,
    lang: "en",
    language: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
    languageOptions: ["en", "kn"],
    resources,
  });

export default i18n;
