import { createSlice } from "@reduxjs/toolkit";

export const unitsSlice = createSlice({
  name: "units",
  initialState: {
    units: [],
    additionalUnits:[],
    activeUnit: {},
    activeChapter: {},
    activeTopic: {},
  },
  reducers: {
    setUnits: (state, action) => {
      state.units = action.payload;
    },
    setAdditionalUnits: (state, action) => {
      state.additionalUnits = action.payload;
    },
    setActiveUnit: (state, action) => {
      state.activeUnit = action.payload;
    },
    setActiveChapter: (state, action) => {
      state.activeChapter = action.payload;
    },
    setActiveTopic: (state, action) => {
      state.activeTopic = action.payload;
    },
  },
});

export const { setUnits, setActiveUnit, setActiveChapter, setActiveTopic, setAdditionalUnits } =
  unitsSlice.actions;

export const selecUnits = (state) => state.units.units;
export const selectAdditionalUnits = (state) => state.units.additionalUnits;
export const selectActiveUnit = (state) => state.units.activeUnit;
export const selectActiveChapter = (state) => state.units.activeChapter;
export const selectActiveTopic = (state) => state.units.activeTopic;

export default unitsSlice.reducer;
