import axios from "axios";

const fetchNotifications = (grade, board, medium) => {
  try {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/v1/notification?grade=${grade}&medium=${medium}&board=${board}`,
    });
  } catch (error) {
    return error;
  }
};

export default fetchNotifications;
