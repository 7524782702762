import { Navigate } from "react-router-dom";

const PrivateRoute = ({ component: Component }) => {
  const token = localStorage.getItem("P_TEACHER_ACCESS_TOKEN");
  return localStorage.getItem("P_TEACHER_ACCESS_TOKEN") ? (
    <Component />
  ) : (
    <Navigate to="/login" />
  );
};
export default PrivateRoute;