import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { userReducer, unitReducer, configReducer } from "../features";

const reducers = combineReducers({
  user: userReducer,
  units: unitReducer,
  appConfig: configReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [logger, thunk],
});

const persistor = persistStore(store);

export { store, persistor };
