import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import screenfull from "screenfull";

import PlayerControls from "./PlayerControls";
import useStyles from "./PlayerStyles";

const format = (seconds) => {
  if (Number.isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

function Player({ defaultFullScreen, videoUrl, setStartVideo, videoTitle }) {
  const classes = useStyles();

  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);

  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [fullScreen, setFullScreen] = useState(defaultFullScreen);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [mouseIdleCount, setMouseIdleCount] = useState(0);

  const handlePlayPause = () => {
    setPlaying((ele) => !ele);
  };

  const handleMute = () => {
    if (muted) {
      setMuted(false);
      setVolume(0.5);
    } else {
      setMuted(true);
      setVolume(0);
    }
  };

  const handleForwardRewind = (type) => {
    if (type === "rewind")
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    else playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handleVolumeChange = (e, newValue) => {
    setVolume(parseFloat(newValue / 100));
    if (newValue === 0) setMuted(true);
    else setMuted(false);
  };

  const handleVolumeSeekUp = (e, newValue) => {
    setVolume(parseFloat(newValue / 100));
    if (newValue === 0) setMuted(true);
    else setMuted(false);
  };

  const handlePlaybackRateChange = (rate) => {
    setPlaybackRate(rate);
  };

  const toggleFullScreen = () => {
    if (screenfull.isEnabled || defaultFullScreen) {
      screenfull.toggle(playerContainerRef.current);
      screenfull.on("change", () => {
        if (!screenfull.isFullscreen) {
          setStartVideo(false);
        }
      });
    }
    setFullScreen((screen) => !screen);
  };

  useEffect(() => {
    toggleFullScreen();
  }, []);

  const handleProgress = (e) => {
    if (mouseIdleCount > 2) {
      controlsRef.current.style.visibility = "hidden";
      setMouseIdleCount(0);
    }

    if (controlsRef.current.style.visibility === "visible") {
      setMouseIdleCount((count) => count + 1);
    }

    if (!seeking) setPlayed(e.played);
  };

  const handleSeekChange = (e, newValue) => {
    setPlayed(parseFloat(newValue / 100));
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (e, newValue) => {
    setSeeking(false);
    playerRef.current.seekTo(newValue / 100);
  };

  const currentTime =
    playerRef && playerRef.current && playerRef.current.getCurrentTime();

  const duration =
    playerRef && playerRef.current && playerRef.current.getDuration();

  const ellapsedTime = format(currentTime);
  const totalDuration = format(duration);

  useEffect(() => {}, []);
  const handleMouseMove = () => {
    controlsRef.current.style.visibility = "visible";
    setMouseIdleCount(0);
  };

  const hanldeMouseLeave = () => {
    controlsRef.current.style.visibility = "hidden";
    setMouseIdleCount(0);
  };

  return (
    <div className={classes.parentWrapper}>
      <div
        ref={playerContainerRef}
        className={classes.playerWrapper}
        onMouseMove={handleMouseMove}
        onMouseLeave={hanldeMouseLeave}
      >
        <div className={classes.playerContainer}>
          <ReactPlayer
            ref={playerRef}
            url={videoUrl}
            muted={muted}
            width="100%"
            height="100%"
            playing={playing}
            volume={volume}
            playbackRate={playbackRate}
            onProgress={handleProgress}
            className={classes.reactPlayer}
          />
        </div>
        <PlayerControls
          ref={controlsRef}
          playing={playing}
          onPlayPause={handlePlayPause}
          onForwardRewind={handleForwardRewind}
          muted={muted}
          onMuted={handleMute}
          onVolumeChange={handleVolumeChange}
          onVolumeSeekUp={handleVolumeSeekUp}
          volume={volume}
          playbackRate={playbackRate}
          onPlaybackRateChange={handlePlaybackRateChange}
          onToggleFullScreen={toggleFullScreen}
          fullScreen={fullScreen}
          played={played}
          onSeek={handleSeekChange}
          onSeekMouseDown={handleSeekMouseDown}
          onSeekMouseUp={handleSeekMouseUp}
          ellapsedTime={ellapsedTime}
          totalDuration={totalDuration}
          defaultFullScreen={defaultFullScreen}
          videoTitle={videoTitle}
        />
      </div>
    </div>
  );
}

export default Player;
