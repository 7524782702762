import axios from "axios";
import instance from "../axios/Axios";
import handleSignInWithGoogle from "../services/auth";
import { getRefreshToken, getDeviceId } from "../utils";

const loginWithGoogle = async (userCredential) => {
  if (!userCredential) {
    const { credential } = await handleSignInWithGoogle();
    userCredential = credential;
  }

  const data = {
    googleToken: userCredential.idToken,
  };
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/student/google-signin`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const signUpWithGoogle = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/student/signup`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const isUserRegistered = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/student/check-registration`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const loginWithEmail = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/student/login`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const signUpWithEmail = async (values) => {
  const ele = values.board.split(" ");

  const data = {
    ...values,
    board: ele[0],
    medium: ele[1],
  };

  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/student/signup`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const sendOtp = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/student/otp/send`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const verifyOtp = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/student/otp/verify`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const logout = async () => {
  const deviceId = await getDeviceId();
  try {
    return await instance({
      method: "delete",
      url: `${process.env.REACT_APP_BASE_URL}/v1/student/auth/logout/${deviceId}`,
      data: { refreshToken: getRefreshToken() },
    });
  } catch (error) {
    return error.response;
  }
};

const forgotPassword = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/student/forgot-password`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const resetPassword = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/student/change-password`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const userCheckIfExists = async(data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v2/student/check`,
      data,
    });
  } catch (error) {
    return error.response;
  }
}

const loginWithOtp = async(data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v2/student/login-with-otp`,
      data
    })
  } catch(error) {
    return error.response;
  }
}

export {
  signUpWithGoogle,
  loginWithGoogle,
  loginWithEmail,
  signUpWithEmail,
  verifyOtp,
  logout,
  forgotPassword,
  resetPassword,
  isUserRegistered,
  sendOtp,
  userCheckIfExists,
  loginWithOtp
};
