import axios from "../axios";

const updateProfile = (data) => {
  try {
    return axios({
      method: "put",
      url: "/v1/auth/profile",
      data,
    });
  } catch (error) {
    return error;
  }
};

const getProfile = () => {
  try {
    return axios({
      method: "get",
      url: "/v1/auth/profile",
    });
  } catch (error) {
    return error;
  }
};

export { updateProfile, getProfile };
