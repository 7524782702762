import React, { useState } from "react";
import "./PasswordField.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function PasswordField({ placeholder, name, id, onChange, onBlur, value }) {
  const [visible, setVisible] = useState(false);
  return (
    <div className="passwordField">
      <input
        type={visible ? "text" : "password"}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="passwordField__input"
        placeholder={placeholder || "Enter Value"}
      />
      {visible ? (
        <VisibilityIcon onClick={() => setVisible(!visible)} />
      ) : (
        <VisibilityOffIcon onClick={() => setVisible(!visible)} />
      )}
    </div>
  );
}

export default PasswordField;
