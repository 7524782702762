import i18n from "i18next";

import { doubleDownArrow } from "../../../assets/images";
import dot from "../../../assets/images/landingpage/purpleDot.png";
import RightImg from "../../../assets/images/landingpage/right-learning-potential-new.png";
import "./LearningPotential.css";

function LearningPotential() {
  const scrollToAppPromotion = (e) => {
    e.preventDefault();
    document.getElementById("bottom-slider").scrollIntoView();
  };
  const title = {
    Fst: i18n.t("home:learningSection.mainTitle.Fst"),
    snd: i18n.t("home:learningSection.mainTitle.snd"),
  };
  return (
    <div className="learning-potential-section">

        <div className="learning-section">
          <div className="left-section">
            <div className="title">
              <span className="color-white">{title.Fst} </span>
              <span className="color-FFEB3B">{title.snd} </span>
            </div>
            <div className="sub-title">
              {i18n.t("home:learningSection.subTitle")}
            </div>
            <div>
              <div className="ul-div">
                <img src={dot} alt="" />

                <div className="li-text">
                  {i18n.t("home:learningSection.1")}
                </div>
              </div>
              <div className="ul-div">
                <img src={dot} alt="" />

                <div className="li-text">
                  {i18n.t("home:learningSection.2")}
                </div>
              </div>
              <div className="ul-div">
                <img src={dot} alt="" />

                <div className="li-text">
                  {i18n.t("home:learningSection.3")}
                </div>
              </div>
              <div className="ul-div">
                <img src={dot} alt="" />

                <div className="li-text">
                  {i18n.t("home:learningSection.4")}
                </div>
              </div>
              <div className="ul-div">
                <img src={dot} alt="" />
                <div className="li-text">
                  {i18n.t("home:learningSection.5")}
                </div>
              </div>
            </div>
            <div className="button-section">
              <a href="/" onClick={scrollToAppPromotion} target="new">
                {i18n.t("home:learningSection.buttonName")}

                <img
                  src={doubleDownArrow}
                  height={27}
                  alt="download"
                  className="svg-button"
                />
              </a>
            </div>
          </div>
          <div className="right-section">
            <img src={RightImg} alt="cherri-learn-right" />
          </div>
        </div>
    </div>
  );
}

export default LearningPotential;
