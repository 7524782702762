import i18n from "i18next";
import "react-alice-carousel/lib/alice-carousel.css";
import img5 from "../../../assets/images/landingpage/cherriss.png";
import a from "./a.svg";
import icon from "./icon.svg";
// import macButton from "../../assets/macButton.png";
// import windowsButton from "../../assets/windowsButton.png";
import "./bottomSlider.css";

function BottomSlider() {
  return (
    <div className="bottom-slider-wrapper">
 
        <div className="bottom-slider" id="bottom-slider">
          <div className="link-info">
            <div className="left-section">
              <div className="title">
                <span className="color-FFFFFF">
                  {i18n.t("home:bottomSlider.Getthe")}{" "}
                </span>
                <span className="color-FFEB3B">
                  {i18n.t("home:bottomSlider.learningapp")}
                </span>
              </div>
              <div className="sub-title">
                {i18n.t("home:bottomSlider.subTitle")}
              </div>
              <div className="link-button">
                <a
                  href="https://play.google.com/store/apps/details?id=com.cherrilearn.fun"
                  target="new"
                >
                  <div className="bg-color-FFFFFF button-section">
                    <div className="icon-div">
                      <img src={a} alt="" />
                    </div>
                    <div className="content">
                      <div>{i18n.t("home:bottomSlider.GETINON")}</div>
                      <div className="con-sub-title">
                        {i18n.t("home:bottomSlider.GooglePlay")}
                      </div>
                    </div>
                  </div>
                </a>
               
              </div>
            </div>
            <div className="right-section">
              <div className="image-section">
                <img src={img5} alt="" />
              </div>
            </div>
          </div>
    
        </div>
    </div>
  );
}
export default BottomSlider;
