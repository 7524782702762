import React from "react";
import ReactDOM from "react-dom";
import "./Modal.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Checkin, Logout, ActivityScore, UnLockUnit } from "./vareities";

const Modal = ({
  isShowing,
  hide,
  coins,
  type,
  handleLogout,
  activityScore,
  totalScore,
  handleUnlockUnit,
}) => {
  const modalType = () => {
    switch (type) {
      case "checkin":
        return <Checkin coins={coins} hide={hide} />;
      case "logout":
        return <Logout hide={hide} handleLogout={handleLogout} />;
      case "activityScore":
        return (
          <ActivityScore
            activityScore={activityScore}
            hide={hide}
            totalScore={totalScore}
          />
        );
      case "unLockUnit":
        return <UnLockUnit hide={hide} handleUnlockUnit={handleUnlockUnit} />;
      default:
        return <></>;
    }
  };
  return isShowing
    ? ReactDOM.createPortal(
        <>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal">
              <div className="modal-bg">
                <div className="modal-header">
                  <button
                    type="button"
                    className="modal-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <CloseOutlinedIcon />
                  </button>
                </div>
                <div className="modal-body">{modalType()}</div>
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;
};

export default Modal;
