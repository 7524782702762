import React from "react";
import "./MemberCard.css";
import { uid } from "react-uid";
// import { teamMember1 } from '../../../../assets/images';

function MemberCard({ member }) {
  return (
    <div className="memberCard">
      <div className="memberCardContainer">
        <img src={member.imageUrl} alt="" />
        <div className="memberCardContainer__Content">
          {member.exp.map((x) => (
            <p key={uid(x)}>{x}</p>
          ))}
        </div>
      </div>
      <div className="memberCardBottom">
        <h1>{member.name}</h1>
        <p>{member.proffesion}</p>
      </div>
    </div>
  );
}

export default MemberCard;
