import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Otp.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { loginWithOtp, verifyOtp } from "../../../apis/auth";
import { cherriHoldingMobile } from "../../../assets/images/auth";
import MuiButtonSolid from "../../../components/Buttons/MuiButtons";
import { requestForToken } from "../../../configs/firebase.config";
import { setUser } from "../../../features/userSlice";
import { getDeviceId, setJwtTokens } from "../../../utils";
import Navbar from "../../../components/Navbar";
import { Footer } from "../../../components";
import { useAuthContext } from "../contexts/AuthContext";

const containerStyle = {
  margin: "1rem 0",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  gap: 5,
  alignItems: "center",
};

const otpInputField = {
  outlineWidth: "0",
  width: "2rem",
  height: "2rem",
  border: "2px solid #e0e0e0",
  textAlign: "center",
  borderRadius: "8px",
  fontSize: "1rem",
};

const otpInputFocusStyle = {
  border: "2px solid blue",
};

const otpInputErrorStyle = {
  border: "2px solid red",
};

const Otp = () => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const { phone, otpSecret } = useAuthContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    requestForToken();
  }, []);

  const handleVerifyOtp = async () => {
    if (!otp || otp.length !== 6) {
      toast.error("Enter valid OTP");
      return;
    }

    const response = await verifyOtp({
      otp,
      otpSecret,
      phone,
      verifyPhoneNumber: true,
    });
    if (response.data.success) {
      toast.success("OTP verified");
      setOtp("");
      navigate("/namepassword");
    }
  };

  return (
    <>
      <Navbar />
      <div className="otp">
        <div className="otpCotainer">
          <div className="otpContainerLeft">
            <img
              className="cherriImage"
              src={cherriHoldingMobile}
              alt="cherri Holding Mobile"
            />
          </div>
          <div className="otpContainerRight">
            <h1>Verify Phone Number</h1>
            <p>Check your SMS message</p>
            <p>OTP sent to {phone}</p>
            <OtpInput
              value={otp}
              onChange={(e) => setOtp(e)}
              numInputs={6}
              containerStyle={containerStyle}
              inputStyle={otpInputField}
              focusStyle={otpInputFocusStyle}
              errorStyle={otpInputErrorStyle}
              hasErrored={!!error}
              isInputNum
            />
            <MuiButtonSolid
              EndIcon={ArrowRightAltIcon}
              title="Verify Now"
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => handleVerifyOtp()}
              size="large"
              fontSize="1rem"
              disabled={false}
            />
            <p>
              Did’t you receive any code?<strong> Resend Code</strong>
            </p>
            <div className="otpContainerRightBottom">
              <p>
                <strong>By Continuing, you agree to CherriLearn</strong>
              </p>
              <div className="marginer" />
              <p>
                <Link to="/terms-conditions">Terms and Conditions</Link> &{" "}
                <Link to="/terms-conditions">Privacy Policy</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Otp;
