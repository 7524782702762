import React from "react";
import { coin } from "../../../assets/images/popup";

function Checkin({ coins, hide }) {
  return (
    <>
      <img src={coin} alt="coin" />
      <span>{coins} coins recieved</span>
      <p>Check In Success</p>
      <button type="button" className="modal-button" onClick={hide}>
        OK!
      </button>
    </>
  );
}

export default Checkin;
