import { Box, Modal } from "@mui/material";
import i18n from "i18next";
import { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { X } from "react-feather";
import { uid } from "react-uid";
import anithaPinto from "../../../assets/images/landingpage/anitha pinto.png";
import meera from "../../../assets/images/landingpage/meera.png";
import player from "../../../assets/images/landingpage/player.png";
import shabheer from "../../../assets/images/landingpage/shabheer.png";
import star from "../../../assets/images/landingpage/star.png";
import vedavathi from "../../../assets/images/landingpage/vedavathi.png";
import "./talkSection.css";

function TalkSection() {
  const slider = [
    {
      bgImg: vedavathi,
      img: player,
      title: i18n.t("home:talkSection.array.0.title"),
      des: i18n.t("home:talkSection.array.0.des"),
      subTitle: i18n.t("home:talkSection.array.0.subTitle"),
      starImg: star,
      url: i18n.t("home:talkSection.array.0.url"),
    },
    {
      bgImg: shabheer,
      img: player,
      title: i18n.t("home:talkSection.array.1.title"),
      des: i18n.t("home:talkSection.array.1.des"),
      subTitle: i18n.t("home:talkSection.array.1.subTitle"),
      starImg: star,
      url: i18n.t("home:talkSection.array.1.url"),
    },
    {
      bgImg: meera,
      img: player,
      title: i18n.t("home:talkSection.array.2.title"),
      des: i18n.t("home:talkSection.array.2.des"),
      subTitle: i18n.t("home:talkSection.array.2.subTitle"),
      starImg: star,
      url: i18n.t("home:talkSection.array.2.url"),
    },
    {
      bgImg: anithaPinto,
      img: player,
      title: i18n.t("home:talkSection.array.3.title"),
      des: i18n.t("home:talkSection.array.3.des"),
      subTitle: i18n.t("home:talkSection.array.3.subTitle"),
      starImg: star,
      url: i18n.t("home:talkSection.array.3.url"),
    },
  ];
  const [play, setPlay] = useState(false);
  const playVideo = () => {
    setPlay(true);
  };
  const [videourl, setVideoUrl] = useState("");
  const handleClose = () => {
    setPlay(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "47%",
    transform: "translate(-50%, -50%)",
    maxWidth: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    width: "900px",
    height: "400px",
    margin: "10px",
    "&:focus-visible": {
      outline: "none",
    },
  };
  const closeStyle = {
    display: "flex",
    justifyContent: "end",
    margin: "2rem",
    color: "white",
    cursor: "pointer",
    "&:focus-visible": {
      outline: "none",
    },
  };
  const main = {
    "&:focus-visible": {
      outline: "none",
    },
  };
  const title = {
    fst: i18n.t("home:talkSection.mainTitle.fst"),
    snd: i18n.t("home:talkSection.mainTitle.snd"),
    sub: i18n.t("home:talkSection.subTitle"),
  };
  return (
    <div className="talk-section-content">
        <Modal
          open={play}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={main}>
            <Box sx={closeStyle}>
              <X onClick={handleClose} />
            </Box>
            <Box sx={style}>
              <iframe
                src={videourl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                height="100%"
                width="100%"
              />
            </Box>
          </Box>
        </Modal>

        <div className="talk-section">
          <div className="title-section">
            <div className="title">
                <span className="span-text-color">{title.fst} </span>
               <span> {title.snd}</span>
            </div>
            <div className="sub-title">{title.sub}</div>
            
          </div>

          <div className="slider-section">
            <AliceCarousel
              mouseTracking
              responsive={{
                0: { items: 1 },
                568: { items: 1 },
                1024: { items: 3 },
              }}
              activeIndex={1}
              autoPlay
              infinite
              disableButtonsControls
              animationDuration={2000}
            >
              {slider.map((obj) => (
                <div className="card" key={uid(obj)}>
                  <div className="main-image-section">
                    <div className="image-section">
                      <img src={obj.bgImg} alt="" />
                    </div>
                    <div
                      className="play-image-section"
                      role="button"
                      tabIndex="0"
                      onClick={() => {
                        playVideo();
                        setVideoUrl(obj.url);
                      }}
                      onKeyDown={() => {
                        playVideo();
                        setVideoUrl(obj.url);
                      }}
                    >
                      <img src={obj.img} alt="" />
                    </div>
                  </div>
                  <div className="card-des">
                    <div className="title">{obj.title}</div>
                    <div className="sub-title">{obj.des}</div>
                    <div className="des-title">{obj.subTitle}</div>
                  </div>
                </div>
              ))}
            </AliceCarousel>
          </div>
        </div>
    </div>
  );
}
export default TalkSection;
