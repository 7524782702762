import React from "react";
import "./FormikSelect.css";

function FormikSelect({
  borderBlack,
  defaultValue,
  data,
  onChange,
  onBlur,
  value,
  name,
}) {
  return (
    <div className={`formikselect ${borderBlack && "formikselectBlack"}`}>
      <select
        onChange={(selectedOption) => {
          const event = {
            target: { name, value: selectedOption.target.value },
          };
          onChange(event);
        }}
        onBlur={() => {
          onBlur({ target: { name } });
        }}
      >
        <option value={value} selected="selected" hidden="hidden">
          {defaultValue || "select..."}
        </option>
        {data.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FormikSelect;
