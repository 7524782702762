import React, { useState } from "react";
// assets
import { useNavigate } from "react-router-dom";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { cherriHoldingMobile } from "../../../assets/images/auth";
import MuiButtonSolid from "../../../components/Buttons/MuiButtons";

import { Footer } from "../../../components";
import { useAuthContext } from "../contexts/AuthContext";
import "./Gender.css";
import Navbar from "../../../components/Navbar";

const Gender = () => {
  const [female, setFemale] = useState(false);
  const [male, setMale] = useState(false);
  const { gender, setGender } = useAuthContext();
  const navigate = useNavigate();
  const handleSelectedGender = (gen) => {
    if (gen === "Girl") {
      setMale(false);
      setFemale(true);
    } else {
      setMale(true);
      setFemale(false);
    }
    setGender(gen);
  };

  return (
    <>
      <Navbar />
      <div className="gender">
        <div className="genderCotainer">
          <div className="genderContainerLeft">
            <img
              className="cherriImage"
              src={cherriHoldingMobile}
              alt="cherri Holding Mobile"
            />
          </div>
          <div className="genderContainerRight">
            <h1>What&apos;s your Gender?</h1>
            <div className="genderContainerRightRow">
              <button
                id="female"
                type="button"
                className="genderContainerRightBox"
                style={{
                  backgroundColor: female
                    ? "rgba(255, 142, 158, 0.12)"
                    : "#ffffff",
                }}
                onClick={() => handleSelectedGender("Female")}
              >
                <CheckCircleIcon
                  sx={{ color: female ? "#31D95E" : "#A9B5AC" }}
                />
                <p>Female</p>
              </button>
              <button
                id="male"
                className="genderContainerRightBox"
                style={{
                  backgroundColor: male
                    ? "rgba(255, 142, 158, 0.12)"
                    : "#ffffff",
                }}
                type="button"
                onClick={() => handleSelectedGender("Male")}
              >
                <CheckCircleIcon sx={{ color: male ? "#31D95E" : "#A9B5AC" }} />
                <p>Male</p>
              </button>
            </div>
            <MuiButtonSolid
              EndIcon={ArrowRightAltIcon}
              title="Continue"
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                navigate("/selectclass");
              }}
              size="medium"
              fontSize="1rem"
              disabled={false}
            />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Gender;
