const arrayToString = (array, type) => {
  let string = "";
  if (type === "WORD") {
    string = array.join("");
  } else {
    array.forEach((element, i) => {
      if (i === array.length - 1) {
        string += element;
      } else {
        string += `${element} `;
      }
    });
  }
  return string;
};

export default arrayToString;
