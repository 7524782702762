const checkPremiumAccess = (user) => {
  const hasPremiumAccess = user.activeSubscriptions.map((subscription) => {
    if (
      subscription.type.includes("ALL_ACCESS") &&
      user.grade === subscription.grade &&
      new Date(subscription.endDate) > Date.now()
    ) {
      return true;
    }
    return false;
  });

  const hasVavAccess = user.activeSubscriptions.map((subscription) => {
    if (
      subscription.type.includes("VAV") &&
      user.grade === subscription.grade &&
      new Date(subscription.endDate) > Date.now()
    ) {
      return true;
    }
    return false;
  });

  return [hasPremiumAccess.includes(true), hasVavAccess.includes(true)];
};

export default checkPremiumAccess;
