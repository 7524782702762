import React, { forwardRef } from "react";
// import { uid } from 'react-uid';

// mui components
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Forward10Icon from "@mui/icons-material/Forward10";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Replay10Icon from "@mui/icons-material/Replay10";
// import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
// import Popover from '@mui/material/Popover';
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

// styles
import useStyles from "./PlayerStyles";

const PrettoSlider = styled(Slider)({
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "primary",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export default forwardRef(
  (
    {
      playing,
      onPlayPause,
      onForwardRewind,
      muted,
      onMuted,
      onVolumeChange,
      onVolumeSeekUp,
      volume,
      // onPlaybackRateChange,
      // playbackRate,
      onToggleFullScreen,
      fullScreen,
      played,
      onSeek,
      onSeekMouseDown,
      onSeekMouseUp,
      ellapsedTime,
      totalDuration,
      defaultFullScreen,
      videoTitle,
    },
    ref
  ) => {
    const classes = useStyles();

    // const [anchorEl, setAnchorEl] = useState(false);
    // const [anchorE2, setAnchorE2] = useState(false);

    // const id = anchorEl ? 'playbackrate-popover' : undefined;
    // const id2 = anchorEl ? 'quality-popover' : undefined;

    // const handleRateClick = (rate) => {
    //   onPlaybackRateChange(rate);
    //   setAnchorEl(false);
    // };

    return (
      <div className={classes.controlsPlayer} ref={ref}>
        {/* Top Controls */}
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: 16 }}
        >
          <Grid item>
            <Typography variant="h5" className={classes.playerTitle}>
              {videoTitle}
            </Typography>
          </Grid>
          {/* <Grid item>
            <IconButton onClick={(event) => setAnchorE2(event.currentTarget)}>
              <SettingsIcon color='primary' className={classes.settingsIcon} />
            </IconButton>
            <Popover
              id={id2}
              open={anchorE2}
              anchorEl={anchorE2}
              onClose={() => setAnchorE2(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Grid container direction='column-reverse'>
                {[360, 480, 720, 1080].map((quality) => (
                  <Button
                    variant='text'
                    className={classes.bottomIcons}
                    key={uid(quality)}
                  >
                    <Typography color='#999'>{quality}p</Typography>
                  </Button>
                ))}
              </Grid>
            </Popover>
          </Grid> */}
        </Grid>
        {/* middle controlls */}
        <Grid container className={classes.middleControls}>
          <Grid item>
            <IconButton onClick={() => onForwardRewind("rewind")}>
              <Replay10Icon color="primary" className={classes.controlIcon} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={onPlayPause}>
              {playing ? (
                <PauseIcon className={classes.controlIcon} />
              ) : (
                <PlayArrowIcon className={classes.controlIcon} />
              )}
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={() => onForwardRewind("forward")}>
              <Forward10Icon color="primary" className={classes.controlIcon} />
            </IconButton>
          </Grid>
        </Grid>

        {/* bottom controlls */}
        <Grid container className={classes.bottomControls}>
          <Grid item width="100%" padding="0px 15px">
            <PrettoSlider
              min={0}
              max={100}
              valueLabelDisplay="off"
              value={played * 100}
              onChange={onSeek}
              onMouseDown={onSeekMouseDown}
              onChangeCommitted={onSeekMouseUp}
              className={classes.PrettoSlider}
            />
          </Grid>
          <Grid item>
            <Grid container className={classes.bottomLeftControls}>
              <IconButton onClick={onPlayPause} className={classes.bottomIcons}>
                {playing ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>

              <IconButton onClick={onMuted} className={classes.bottomIcons}>
                {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
              </IconButton>

              <Slider
                size="small"
                className={classes.volumeSlider}
                min={0}
                max={100}
                value={volume * 100}
                onChange={onVolumeChange}
                onChangeCommitted={onVolumeSeekUp}
              />

              <Typography className={classes.timer}>
                {ellapsedTime}/{totalDuration}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {/* <Button
              variant='text'
              className={classes.bottomIcons}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <Typography>{playbackRate}x</Typography>
            </Button>
            <Popover
              id={id}
              open={anchorEl}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Grid container direction='column-reverse'>
                {[0.5, 1, 1.5, 2].map((rate) => (
                  <Button
                    key={uid(rate)}
                    variant='text'
                    className={
                      playbackRate === rate
                        ? classes.activeRate
                        : classes.bottomIcons
                    }
                    onClick={() => handleRateClick(rate)}
                  >
                    <Typography color='#999' variant='h6'>
                      {rate}x
                    </Typography>
                  </Button>
                ))}
              </Grid>
            </Popover> */}
            {defaultFullScreen && (
              <IconButton onClick={onToggleFullScreen}>
                {defaultFullScreen || fullScreen ? (
                  <FullscreenExitIcon className={classes.bottomIcons} />
                ) : (
                  <FullscreenIcon className={classes.bottomIcons} />
                )}
              </IconButton>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
);
