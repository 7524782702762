import FingerprintJS from "@fingerprintjs/fingerprintjs";

const getDeviceId = async () => {
  const fpPromise = FingerprintJS.load();
  const fp = await fpPromise;
  const result = await fp.get();
  const { visitorId } = result;
  return visitorId;
};

export default getDeviceId;
