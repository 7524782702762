import { createContext, useContext, useState } from "react";
import { useForm } from "react-hook-form";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [studentName, setStudentName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [grade, setGrade] = useState("");
  const [board, setBoard] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [otpSecret, setOtpSecret] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [taluk, setTaluk] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <AuthContext.Provider
      value={{
        studentName,
        setStudentName,
        email,
        setEmail,
        phone,
        setPhone,
        password,
        setPassword,
        gender,
        setGender,
        grade,
        setGrade,
        board,
        setBoard,
        schoolName,
        setSchoolName,
        state,
        setState,
        district,
        setDistrict,
        taluk,
        setTaluk,
        otpSecret,
        setOtpSecret,
        referralCode,setReferralCode,
        register,
        errors,
        handleSubmit,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
