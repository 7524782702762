/* eslint-disable no-underscore-dangle */
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import instance from "./axios";
import "./configs/firebase.config";
import {
  selectUser,
  setLogout,
  setPremiumAccess,
  // setUser,
  setVavAccess,
} from "./features/userSlice";
import AppRoutes from "./routes/App.routes";
import {
  ScrollToTop,
  checkPremiumAccess,
  getRefreshToken,
  removeJwtTokens,
  setJwtTokens,
} from "./utils";

function App() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  // const { isShowing, toggle } = useModal();
  const navigate = useNavigate();

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 401 && !originalRequest?._retry) {
        originalRequest._retry = true;
        try {
          const oldRefreshToken = getRefreshToken();
          if (oldRefreshToken) {
            const result = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/v1/student/refresh-token`,
              {
                refreshToken: oldRefreshToken,
              }
            );
            if (result.data.success) {
              const { accessToken, refreshToken } = result.data.data;
              setJwtTokens(accessToken, refreshToken);
              originalRequest.headers.authorization = `Bearer ${accessToken}`;
              return axios(originalRequest);
            }
          } else {
            removeJwtTokens();
            dispatch(setLogout({}));
            navigate("/login");
          }
        } catch (err) {
          if (err?.response?.status === 401) {
            removeJwtTokens();
            dispatch(setLogout({}));
            navigate("/login");
          }
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (user._id) {
      const [hasPremiumAccess, hasVavAccess] = checkPremiumAccess(user);
      dispatch(setPremiumAccess(hasPremiumAccess));
      dispatch(setVavAccess(hasVavAccess));
    }
  }, [user.activeSubscriptions]);

  // const handleShowPopup = useCallback(() => {
  //   if (user.rewardedCoins > 0) {
  //     toggle();
  //     console.log(user.rewardedCoins);
  //     dispatch(setUser({ ...user, rewardedCoins: 0 }));
  //   }
  // }, [user]);

  // useEffect(() => {
  //   handleShowPopup();
  // }, []);

  return (
    <>
      <ScrollToTop>
        <AppRoutes />
      </ScrollToTop>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
