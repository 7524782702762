import i18n from "i18next";
import "react-alice-carousel/lib/alice-carousel.css";
import { withTranslation } from "react-i18next";
import { googlePlayImg1 } from "../../../assets/images";
import RightBGImg from "../../../assets/images/landingpage/BGRNew.png";
import RightImg from "../../../assets/images/landingpage/Cheerigif.gif";
import "./MainSection.css";

function MainSection() {
  return (
    <>
      <div className="main-section">
        <div className="left-section color-FFFFFF">
          <div className="title">
            <span className="color-FFEB3B">
              {i18n.t("home:mainSection.mainTitle.0")}
            </span>
            {i18n.t("home:mainSection.mainTitle.1")}
            <span className="color-FFEB3B">
              {i18n.t("home:mainSection.mainTitle.2")}
            </span>
            {i18n.t("home:mainSection.mainTitle.3")}{" "}
            <span className="color-FFEB3B">
              {i18n.t("home:mainSection.mainTitle.4")}{" "}
            </span>
          </div>
          <div className="sub-title">{i18n.t("home:mainSection.subTitle")}</div>

          <div className="footerRight__columnRow main-download">
            <a
              href="https://play.google.com/store/apps/details?id=com.cherrilearn.fun"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googlePlayImg1} alt="" />
            </a>
          </div>
        </div>
        <div className="right-section">
          <div className="img-div">
            <img src={RightBGImg} alt="rightImage" className="" />
            <img src={RightImg} alt="rightImage" className="gif-div-img" />
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(MainSection);
