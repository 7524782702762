import React, { useEffect } from "react";
import "./Header.css";
import { profile } from "../../assets/images";
import HeaderTitle from "./HeaderTitle";

function Header({ counter, setCounter, handleCheck, heading, instruction }) {
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  useEffect(() => {
    if (counter <= 0) {
      setCounter(0);
      handleCheck();
    }
  }, [counter]);

  return (
    <div className="header">
      <div className="headerContainer">
        <div className="headerLogo">
          <img src={profile} alt="profile" />
        </div>
        <div className="headerCurve">
          {/* <img src={headerCurve} alt='headerCurve' /> */}
          <HeaderTitle
            counter={counter}
            heading={heading}
            instruction={instruction}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
